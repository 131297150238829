.profileForm {
    width: 50%;
    background: transparent !important;
    padding: 20px 50px;
    margin: 20px auto;
    border: 1px dashed #424242b0;
    border-radius: 20px;
}

.profileForm form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.profileForm form label {
    display: block;
    color: #121212;
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 3px;
}

.profileForm form input, textarea {
    width: 100%;
    background-color: #ffffff21;
    padding: 11px 15px;
    display: block;
    color: #121212;
    font-size: 13px;
    border-radius: 10px;
    box-shadow: 2px 4px 4px 4px #12311d2d;
    border: 1px dashed #3d3d3d67;
}

.profileForm form .disabled {
    width: 100%;
    background-color: #ffffff21;
    padding: 11px 15px;
    display: block;
    color: #121212;
    font-size: 13px;
    border-radius: 10px;
    box-shadow: 2px 4px 4px 4px #12311d2d;
    border: 1px dashed #3d3d3d67;
}

.profileForm form input::placeholder{
    color: #121212;
    opacity: 0.5;
    font-size: 13px;
}

.profileForm > form > div {
    margin: 10px 0;
}

.profileForm form button {
    width: 100%;
    display: block;
    background-color: #BBD51A;
    padding: 15px 20px;
    margin: 30px 0 18px 0;
    border-spacing: 1em;
    color: #121212;
    border-radius: 40px;
    box-shadow: 7px 7px 9px #163b238a;
    font-size: 15px;
    font-weight: 600;
    border: 0;
}

.profileForm form .error {
    color: #BBD51A;
    font-weight: 600;
    font-size: 13px;
    padding: 0 !important;
    margin: 0 !important;
}



@media screen and (max-width: 768px)
{
    .profileForm {
        width: 90%;
        margin: 50px auto;
        padding: 30px;
    }

    .profileForm form {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .profileForm > form > div {
        margin: 10px 0;
    }
}