.footer {
    display: none;
}



@media screen and (max-width: 768px) {
    .footer {
        display: block;
        padding-top: 30px;
        padding-bottom: 10px;
        font-size: 13px;
        text-align: center;
    }
    .footer p > span {
        font-size: 14px;
        display: block;
        margin-bottom: 2px;
        font-weight: 600;
    }
}