.transaction {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    padding-bottom: 20px;
}

.transactionPage {
    width: 50%;
    margin: 50px auto;
    background: #fff !important;
    padding: 20px 20px 40px 20px;
    box-shadow: 3px 5px 3px 5px #000c0252 !important;
}


.transactionPage .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.transactionPage .header img {
    width: auto;
    height: 40px;
    border: 0px !important;
    border-radius: 0 !important;
}

.transactionPage .header span {
    font-weight: 500;
    font-size: 13px;
}

.transactionPage .amount {
    text-align: center;
    color: #037566;
    font-weight: 600;
    font-size: 18px;
    padding: 5px 0;
}

.transactionPage .status {
    text-align: center;
    color: #121212 !important;
    font-weight: 500;
    font-size: 17px;
    padding: 10px 0;
    text-transform: uppercase;
}
.transactionPage .date {
    text-align: center;
    color: #6e6e6ee5 !important;
    font-weight: 500;
    font-size: 11px;
    padding: 3px 0;
    text-transform: uppercase;
}


.transactionPage .notice {
    font-size: 13px;
    font-weight: 400;
    padding: 10px 0;
    color: #121212b9;
}

.transactionPage .account {
    font-size: 13px;
    font-weight: 600;
    padding: 0;
    color: #000000e0;
    text-align: center;
}

.transactionPage .key {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
    color: #555555;
    font-size: 12px;
}
.transactionPage .value {
    width: 60%;
    align-items: flex-end;
    display: flex;
    justify-content: flex-end;
    font-size: 12px;
    font-weight: 600;
}



.transactionPage .br {
    text-align: center;
    background: #363636 !important;
    padding: 0.3px 0;
    width: 88%;
    margin: 10px auto;
}

.transactionPage .br2 {
    text-align: center;
    border: 0.8px dashed #000000d2;
    padding: 0;
    width: 100%;
    margin: 13px auto;
}









@media screen and (max-width: 768px)
{
    .transactionPage {
        width: 90%;
        margin: 50px auto;
    }
}