.mainInvestment .parentContainer {
    border-radius: 10px;
    height: 12rem !important;
}

.mainInvestment .cards {
    padding-top: 50px;
}

.mainInvestment .parentContainer .CompactCard {
    display: flex;
    height: 100%;
    width: 100%;
    border-radius: 0.7rem;
    color: white;
    padding: 1rem 0.5rem;
    cursor: pointer;
    box-shadow: 3px 3px 3px 3px #051f0854;
}

.CompactCard:hover {
    box-shadow: none !important;
}

.mainInvestment .CompactCard .radialBar {
    display: flex;
    /* flex: 1 1; */
    flex-direction: column;
    justify-content: flex-end;
    gap: 1rem
}
.CircularProgressbar {
    width: 4rem !important;
    overflow: visible;
}
.CircularProgressbar-path {
    stroke: white !important;
    stroke-width: 12px !important;
    filter: drop-shadow(2px 4px 6px white);
}
.CircularProgressbar-trail {
    display: none;
}
.CircularProgressbar-text {
    fill: white !important;
}

.details {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
}

.mainInvestment .parentContainer .CompactCard:nth-child(2) {
    color: #685235 !important
}


.mainInvestment .CompactCard> .radialBar>span{
    font-weight: 600 !important;
    font-size: 14px !important;
}
.mainInvestment .CompactCard> .details>div:nth-of-type(1)>span:nth-of-type(1){
    font-weight: 400;
    font-size: 11px;
}
.mainInvestment .CompactCard> .details>div:nth-of-type(1)>span:nth-of-type(2){
    font-weight: 600;
    font-size: 18px;
}
.mainInvestment .CompactCard> .details>span{
    font-weight: 400;
    font-size: 12px;
}




.parentContainer .ExpandedCard {
    position: absolute;
    width: 60%;
    height: 70vh;
    z-index: 9;
    left: 13rem;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    box-shadow: 3px 3px 3px 3px #051f0854;
}
.parentContainer .ExpandedCard img {
    width: 40%;
}
.parentContainer .ExpandedCard > span:nth-of-type(1) {
    color: #f5f5f5;
    font-size: 26px;
    font-weight: bold;
    text-shadow: 0px 0px 15px rgba(49, 49, 49, 0.493);
    padding: 9px 0;
}
.parentContainer .ExpandedCard > div:nth-of-type(2) {
    font-size: 15px;
    font-weight: 500;
    padding: 5px 0;
}
.parentContainer .ExpandedCard > span:nth-of-type(2) {
    font-size: 15px;
    font-weight: 500;
    padding: 5px 0;
}

.parentContainer .ExpandedCard a {
    display: inline-block;
    background: #00BFA6;
    color:white;
    padding: 10px 60px;
    border-radius: 15px;
    font-size: 14px;
    font-weight: 500;
    text-decoration: none;
    margin: 10px 0;
    box-shadow: 3px 2px 2px 3px #7181735b;
}





@media screen and (max-width: 768px) {

    .mainInvestment .parentContainer {
        height: 22.5vh !important;
        width: 100% !important;
        margin-bottom: 15px;
    }

    .mainInvestment .cards {
        padding-top: 0px;
    }

    .mainInvestment .parentContainer .CompactCard {
        padding: 1rem;
        width: 100% !important;
        /* margin: 50px 0; */
    }

    .mainInvestment .CompactCard> .radialBar>span{
        font-weight: 600 !important;
        font-size: 16px !important;
    }
    .mainInvestment .CompactCard> .details>div:nth-of-type(1)>span:nth-of-type(1){
        font-weight: 400 !important;
        font-size: 12px !important;
    }
    .mainInvestment .CompactCard> .details>div:nth-of-type(1)>span:nth-of-type(2){
        font-weight: 600 !important;
        font-size: 18px !important;
    }
    .mainInvestment .CompactCard> .details>span{
        font-weight: 500 !important;
        font-size: 13px !important;
    }


    .mainInvestment .ExpandedCard {
        top: 10rem;
        height: 50%;
        left: 25px;
        width: 85%;
    }

    .mainInvestment .parentContainer .ExpandedCard > span:nth-of-type(1) {
        font-size: 19px !important;
    }
    
}






@media screen and (max-width: 1200px) {
    .ExpandedCard {
      top: 2rem;
      height: 45vh;
      left: 6rem;
    }
}