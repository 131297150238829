.mainInvestment {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    padding-bottom: 20px;
    /* justify-content: space-evenly; */
}

@media screen and (max-width: 1200px) {
    .mainInvestment{
        justify-content:flex-start;
        margin-top: 2rem;
    }
}

@media screen and (max-width: 768px)
{
    .transaction .subtitle {
        padding: 0px 20px;
    }
    .mainInvestment{
        align-items: center;
    }
}