.sidebar {
  display: flex;
  flex-direction: column;
  position: relative;
  padding-top: 4rem;
  transition: all 300ms ease;
}
/* logo */
.bars{
  display: none;
}

.logo {
  display: flex;
  flex-direction: column;
  height: 5rem;
  font-weight: bold;
  font-size: 22px;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  height: 4%;
  color: #00BFA6
}
.logo > span > span {
  color: var(--deepGreen);
}

.logo > a > img {
  width: 3rem;
  height: 3rem;
}


/* menu */
.menu {
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.menuItem {
  display: flex;
  align-items: center;
  gap: 1rem;
  height: 2rem;
  margin-left: 2rem;
  position: relative;
  transition: all 300ms ease;
  border-radius: 0.7rem;
  font-size: 14px;
  text-decoration: none;
}

.menu .title {
  color: black;
  margin-left: 2rem !important;
  transition: all 300ms ease;
  font-size: 14px;
  padding: 0 !important;
  margin: 0;
  text-decoration: none;
}

.menuItem:hover {
  cursor: pointer;
}

.menu .menuItem:last-child {
  position: absolute;
  bottom: 2.3rem;
  width: 100%;
}

.active {
  background: var(--activeItem);
  margin-left: 0;
  color: white
}
.active::before {
  content: "";
  width: 8px;
  height: 100%;
  background: var(--blueGreen);
  margin-right: calc(1rem - 8px);
}


/* Tablets */
@media screen and (max-width: 1200px) {
  .menuItem>span{
    display: none;
  }
  .logo{
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .sidebar{
    position: fixed;
    z-index: 9;
    background: #022413;
    width: 55%;
    padding-right: 1rem;
    height: 100%;
  }
  .menuItem>span{
    display: block;
  }
  .logo{
    display: flex;
  }
  .logo > span > span {
    color: var(--orange);
  }
  .menuItem {
    color: white;
    height: 1.5rem !important;
    padding: 15px 0;
  }
  
  .menu .menuItem:last-child {
    position: relative;
    margin-top: 100%;
  }
  .bars{
    display: flex;
    position: fixed;
    top: 2rem;
    left:60%;
    background:#ffe0e0;
    padding:10px;
    border-radius: 10px;
    z-index: 9;
  }
  .close{
    left: -60%;
  }
}
