.profile {
    display: flex;
    flex-direction: column;
    /* justify-content: space-evenly; */
    margin-top: 40px;
    padding-bottom: 20px;
}

.profilePage {
    width: 50%;
    margin: 50px auto;
    padding: 0;
    background: #ffffff;
    box-shadow: 3px 5px 3px 5px #000c0252 !important;
    border-radius: 10px;
}

.profilePage .top {
    background: transparent !important;
    padding: 0;
    height: 300px;
    width: 100%;
}

.profilePage img {
    height: 300px;
    width: 100%;
    margin-bottom: 10px;
}
.profilePage .bottom {
    width: 100%;
    background: transparent !important;
    padding: 30px;
    display: flex;
    flex-direction: column;
}

.profilePage .row1 {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}
.profilePage .row1 p:nth-child(1) {
    font-size: 15px;
    font-weight: 600;
    color: #121212;
}
.profilePage .row1 p:nth-child(2) {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    font-size: 13px;
    font-weight: 400;
    color: #1212129d;
}
.profilePage .row1 p:nth-child(2) span:nth-child(2) {
    padding: 5px 0;
}

.profilePage .row2 {
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
}
.profilePage .row2 p:nth-child(1) {
    font-size: 14px;
    color: #121212;
    padding-bottom: 5px;
    border-bottom: 0.5px solid #00000070;
    width: 44%;
}
.profilePage .row2 p:nth-child(2) {
    font-size: 14px;
    color: #121212;
    padding-bottom: 5px;
    border-bottom: 0.5px solid #00000070;
    width: 44%;
}

.profilePage .row3 {
    font-size: 14px;
    color: #121212;
    padding-bottom: 5px;
    border-bottom: 0.5px solid #00000070;
    width: 100%;
    margin: 10px 0;
}

.profilePage .bottom .status {
    padding: 7px 0;
    font-size: 14px;
    font-weight: 500;
    color: #00BFA6;
    display: flex;
}

.profilePage .bottom .status>div {
    padding: 7px;
    height: 5px;
    width: 5px;
    background: #ff0000;
    border-radius: 50%;
    margin-left: 13px;
}

.profile .profilePage .link {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.profile .profilePage .link a:nth-child(1) {
    text-align: center;
    display: inline-block;
    /* width: 50%; */
    background: #095509 !important;
    color: #fff !important;
    padding: 10px 13px;
    border-radius: 20px;
    font-size: 12px;
    font-weight: 500;
    text-decoration: none;
    margin: 0;
}

.profile .profilePage .link a:nth-child(2) {
    text-align: center;
    margin: 0;
    padding-top: 10px;
    width: 100%;
    color: #095509 !important;
    font-size: 14px;
    font-weight: 500;
    text-decoration: underline;
}






@media screen and (max-width: 768px)
{
    .profilePage {
        width: 90%;
        margin: 50px auto;
    }
}