:root {
    --yellow: linear-gradient(180deg, #F8D49A -146.42%, #FAD79D -46.42%);
    --orange: #BBD51A;
    --black: #242d49;
    --gray: #788097;
    --purple: linear-gradient(180deg, #BB67FF 0%, #C484F3 100%);
    --pink: #FF919D;
    --glass: rgba(255, 255, 255, 0.61);
    --boxShadow: 0px 19px 60px rgb(0 0 0 / 8%);
    --smboxShadow: -79px 51px 60px rgba(0, 0, 0, 0.08);
    --activeItem: #26683C;
    --deepGreen: #021E12;
    --blueGreen: #00BFA6;
    /* --card1: #021E12;
    --card2: #cce627; */
}

@font-face {
    font-family: Inter;
    src: url('../fonts/Inter-Bold.ttf');
    font-weight: 700;
}

@font-face {
    font-family: Inter;
    src: url('../fonts/Inter-Medium.ttf');
	font-weight: 500;
}

@font-face {
    font-family: Inter;
    src: url('../fonts/Inter-Light.ttf');
    font-weight: 400;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.App {
    font-family: 'Inter', sans-serif;
    background: linear-gradient(
        106.37deg,
        /* #ffe1bc 29.63%,
        #ffcfd1 51.55%,
        #f3c6f1 90.85% */
        #BBD51A 29.63%,
        #BBD51A 51.55%,
        #00BFA6 90.85%
    );
    color: var(--black);
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.AppGlass {
    display: grid;
    height: 97%;
    width: 97%;
    border-radius: 2rem;
    background-color: var(--glass);
    overflow: hidden;
    overflow-y: scroll;
    grid-template-columns: 11rem auto 20rem;
    gap: 16px;
}

.pageTitle {
    color: var(--deepGreen);
    font-size: 24px;
    padding-top: 3px;
}

.subtitle {
    font-size: 15px;
    padding: 10px 0;
}

.headerInfo {
    font-size: 14px;
    font-weight: 500
}

@media screen and (max-width: 1200px) {
    .AppGlass{
      grid-template-columns: 10% 50% auto;
      overflow-y: scroll !important;
    } 
}
  
@media screen and (max-width: 768px) {
    .AppGlass{
      grid-template-columns: 1fr;
      gap: 1px;
      padding: 20px 0;
    } 
    .AppGlass::-webkit-scrollbar {
        display: none;
    }
    .pageTitle {
        font-size: 24px;
        padding-top: 3px;
        text-align: center;
    }
    .subtitle {
        font-size: 14px;
        padding: 10px 0;
        text-align: center;
        color: #000000a1;
    }
    .headerInfo {
        font-size: 14px;
        font-weight: 500;
        padding: 10px 20px;
    }

    
    
}