.Updates {
  width: 85%;
  height: 500px;
  overflow-y: scroll;
  background: #fff;
  border-radius: 0.7rem;
  border: 1px dashed #068d7b;
  padding: 1rem;
  gap: 1rem;
  display: flex;
  flex-direction: column;
  font-size: 13px;
  box-shadow: 4px 10px 10px 4px #8080803b !important;
}

.update > a {
  display: flex;
  gap: 0.5rem;
  text-decoration: none;
  border: 1px dashed #068d7b;
  /* border-radius: 10px; */
}

.update > a > img {
  width: 3.2rem;
  height: 3.2rem;
}

.update > a .noti > div:nth-child(1) > span {
  font-weight: bold;
  color: #068d7b;
}

.update > a .noti > span {
  color: #121212 !important;
}

@media screen and (max-width: 768px) {
  .Updates {
    width: 90%;
  }

  .CustomerReview {
    width: 90%;
  }
}
