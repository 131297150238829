.mainDash .cards {
    display: flex;
    gap: 10px;
}

.mainDash .parentContainer {
    width: 100%;
}

@media screen and (max-width: 1200px) {
    .mainDash .cards{
      flex-direction: column;
    }
    .mainDash .parentContainer{
      height: 9rem;
    }
}
  
@media screen and (max-width: 768px){
    .mainDash .cards{
      width: 90%;
      margin-bottom: 30px;
    }
}

