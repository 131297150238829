.mainDash {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  gap: 30px;
  /* justify-content: space-evenly; */
}

.mainDash a {
  font-weight: 600;
  font-size: 16px;
  color: #00bfa6;
  text-decoration: none;
}

@media screen and (max-width: 1200px) {
  .mainDash {
    justify-content: flex-start;
    margin-top: 2rem;
  }
}

@media screen and (max-width: 768px) {
  .mainDash {
    align-items: center;
  }

  .mainDash .Table h3 {
    width: 100%;
    text-align: left !important;
    padding: 10px 0;
  }
}
