.withdrawals {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
}

.withdrawalTable {
    margin-top: 40px;
}

.withdrawalTable .amount {
    color: #00b5ff !important;
    font-weight: 600 !important;
  }



@media screen and (max-width: 1200px) {
    .withdrawals{
        justify-content:flex-start;
        margin-top: 2rem;
    }
}

@media screen and (max-width: 768px)
{
    .withdrawals{
        padding: 20px 20px;
        align-items: center;
    }
}